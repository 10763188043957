/* eslint-disable @nx/enforce-module-boundaries */
'use client';
import { AddIcon } from '@chakra-ui/icons';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure } from '@chakra-ui/react';
import { Box, HStack, Link, Text, VStack } from '@mybridge/ui';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadDictionary, setLanguage } from 'v4/store/slices/translationSlice';
export const MainCopyrightsText = forwardRef(({alignment, ...props }, ref) => {
  const { t } = useTranslator();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const { loggedIn } = useSelector((state) => state.user);
  const languageOptions = [
    { code: 'en', name: `English (${t('language.English') || 'English'})` },
    { code: 'fr', name: `French (${t('language.French') || 'French'})` },
    { code: 'de', name: `German (${t('language.German') || 'German'})` },
    { code: 'pt', name: `Portuguese (${t('language.Portuguese') || 'Portuguese'})` },
    { code: 'es', name: `Spanish (${t('language.Spanish') || 'Spanish'})` }
  ];
  const getLanguageDisplayName = (code) => {
    const language = languageOptions.find(lang => lang.code === code);
    return language ? language?.name : 'English'; // Return just the English name
  };
  useEffect(() => {
    // Initialize selected language from localStorage
    const savedLang = localStorage.getItem('userLanguage') || 'en';
    setSelectedLanguage(savedLang);
    console.log('savedLang', savedLang)
  }, []);
  
  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    localStorage.setItem('userLanguage', lang);
    dispatch(loadDictionary(lang));
    dispatch(setLanguage(lang));
    onClose();
  };
  return (
    <>
      <VStack
        textAlign="center"
        fontSize="xs"
        color="gray.500"
        {...props}
        alignItems={alignment}
        mt={3}
      >
        <Box>
          <Text display="inline" as={Link} href="/terms-of-use">
            {t('TnC.termsConditions') || "Terms & Conditions"}
          </Text>
          <Text display="inline">{' · '}</Text>
          <Text display="inline" as={Link} href="/privacy-policy">
            {t('TnC.privacyPolicy') || "Privacy Policy"}
          </Text>
          {!loggedIn && <Box
            variant="ghost"
            onClick={onOpen}
            mr={2}
            cursor='pointer'
          >
            <Text color="brandGray.700" me="10px">

              {getLanguageDisplayName(selectedLanguage)} | {t('settingAccount.chooseLanguage')||"Choose Language"}
            </Text>
          </Box>}
        </Box>
        <Box mt="-6px">
          <Text display="inline" as={Link} href="/business">
            {t('navigation.business') || "Business"}
          </Text>
          <Text display="inline">{' · '}</Text>
          <Text display="inline" as={Link} href="/support">
            {t('TnC.helpSupport') || "Help & Support"}
          </Text>
        </Box>
        <Text> myBridge {t('TnC.corporation') || "Corporation"} © {new Date().getFullYear()}</Text>
        {/* Language Selection Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('settingAccount.chooseLanguage')||"Choose Language"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
              <Stack spacing={3}>
                {languageOptions.map((lang) => (
                  <Button
                    key={lang.code}
                    variant={selectedLanguage === lang.code ? "solid" : "outline"}
                    colorScheme={selectedLanguage === lang.code ? "blue" : "gray"}
                    onClick={() => handleLanguageChange(lang.code)}
                    w="100%"
                  >
                    {lang.name}
                  </Button>
                ))}
              </Stack>
            </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              {t('buttons.cancel') || "Cancel"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </VStack>
    </>
  );
});
