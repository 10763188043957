/* eslint-disable @nx/enforce-module-boundaries */
import { Box, Card } from '@mybridge/ui';
import React, {useState} from 'react';
import Calendar from 'react-calendar';
import { useRouter } from 'next/router';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
export const DashboardCalendar = () => {
  const router = useRouter();
  const [value, onChange] = useState(new Date());
  const { t } = useTranslator();
  return (
    <Box pt={2}>
      {/* <Calendar prev2Label={null} next2Label={null} onChange={(e) => {
            localStorage.setItem('dateSelectedFromDashboard', e.toISOString());
            router.push('/profile/calendar')
          }}
          value={value} /> */}
      <Calendar 
        prev2Label={null} 
        next2Label={null} 
        onChange={(e) => {
          localStorage.setItem('dateSelectedFromDashboard', e.toISOString());
          router.push('/profile/calendar')
        }}
        value={value}
        // Add translations for calendar navigation
        navigationLabel={({ date }) => 
          `${t(`myCalendar.months.${date.toLocaleString('default', { month: 'long' }).toLowerCase()}`)} ${date.getFullYear()}`
        }
        // Add translations for weekday names
        formatShortWeekday={(locale, date) => 
          t(`myCalendar.shortDays.${date.toLocaleString('default', { weekday: 'short' }).toLowerCase()}`)
        }
      />
    </Box>
  );
};
